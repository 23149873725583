import {
  IDefect,
  IFlatRateUnit,
  IJob,
  IPackage,
  IPart,
  Job as AwpClientLibJob
} from '@service-and-repairs/awpintegrationlib';
import {PackageType} from '@service-and-repairs/dms-api';
import {Util} from '../../util/util';

export class Job extends AwpClientLibJob {

  static fromPlainObject(plainJob: object): Job {
    const sourceJob: IJob = AwpClientLibJob.constructFromPlainObject(plainJob);
    const job: Job = new Job(sourceJob.getExternalId(), sourceJob.getTitle());
    job.setIsActive(sourceJob.getIsActive());
    job.setCustomerResponse(job.getCustomerResponse());
    job.setComment(sourceJob.getComment());
    job.setCustomerStatement(sourceJob.getCustomerStatement());
    job.setDefects(sourceJob.getDefects());
    job.setDemandCategory(sourceJob.getDemandCategory() || 'AWP');
    job.setDemandIds(sourceJob.getDemandIds());
    job.setCbsIds(sourceJob.getCbsIds());
    job.setCcmIds(sourceJob.getCcmIds());
    job.setTechnicalCampaignNumbers(sourceJob.getTechnicalCampaignNumbers());
    job.setFlatRateUnits(sourceJob.getFlatRateUnits());
    job.setCustomerResponse(sourceJob.getCustomerResponse());
    job.setIsSelected(sourceJob.getIsSelected());
    job.setPackages(sourceJob.getPackages());
    job.setParts(sourceJob.getParts());
    job.setPositionNumber(sourceJob.getPositionNumber());
    job.setIsFrozenAfterDmsTransfer(sourceJob.getIsFrozenAfterDmsTransfer());
    job.setLastModifiedVersion(sourceJob.getLastModifiedVersion());
    return job;
  }

  static setFlatRateUnitsExternalIdIfMissing(flatRateUnits: IFlatRateUnit[]): void {
    flatRateUnits.forEach((flatRateUnit: IFlatRateUnit): void => {
      if (!flatRateUnit.getExternalId()) {
        flatRateUnit.setExternalId(Util.createRandomUuid());
      }
    });
  }

  hasPackage(servicePackage: IPackage): boolean {
    return servicePackage && this.getPackages().some((pack: IPackage) =>
      pack.getNumber() === servicePackage.getNumber() && pack.getType() === servicePackage.getType()
    );
  }

  addParts(partsToAdd: IPart[]): void {
    const parts: IPart[] = this.getParts();
    partsToAdd.forEach((partToAdd: IPart): void => {
      partToAdd.setIsSelected(true);
      const index: number =
        parts.findIndex((part: IPart): boolean => part.getPartNumber() === partToAdd.getPartNumber());
      if (index >= 0) {
        parts[index].setQuantity(parts[index].getQuantity() + partToAdd.getQuantity());
      } else {
        parts.push(partToAdd);
      }
    });
    this.setIsSelected(true);
    this.setPartPositionNumbers();
  }

  addOrReplaceFlatRateUnits(flatRateUnitsToAdd: IFlatRateUnit[]): void {
    const flatRateUnits: IFlatRateUnit[] = this.getFlatRateUnits();
    flatRateUnitsToAdd.forEach((fruToAdd: IFlatRateUnit): void => {
      fruToAdd.setIsSelected(true);
      flatRateUnits.push(fruToAdd);
    });
    Job.setFlatRateUnitsExternalIdIfMissing(flatRateUnits);
    this.setIsSelected(true);
    this.setFlatRateUnitPositionNumbers();
  }

  addOrReplacePackage(packageToAdd: IPackage): void {
    this.preparePackage(packageToAdd);
    const packages: IPackage[] = this.getPackages();
    const index: number = packages.findIndex(() => this.hasPackage(packageToAdd));
    if (index >= 0) {
      packages[index] = packageToAdd;
    } else {
      packages.push(packageToAdd);
    }
    this.setIsSelected(true);
    this.setPackagePositionNumbers();
  }

  addDefects(defectsToAdd: IDefect[]): void {
    const defects: IDefect[] = this.getDefects();
    defectsToAdd.forEach((defectToAdd: IDefect): void => {
      defectToAdd.setIsSelected(true);
      const index: number = defects.findIndex((defect: IDefect): boolean => defect.getCode() === defectToAdd.getCode());
      if (index >= 0) {
        defects[index] = defectToAdd;
      } else {
        defects.push(defectToAdd);
      }
    });
    this.setIsSelected(true);
    this.setDefectPositionNumbers();
  }

  isEmpty(): boolean {
    return this.getParts().length === 0
      && this.getFlatRateUnits().length === 0
      && this.getDefects().length === 0
      && this.getPackages().length === 0;
  }

  private setSelectedOnAllItems(isSelected: boolean): void {
    this.setIsSelected(isSelected);

    this.getParts().forEach((part: IPart) => part.setIsSelected(isSelected));
    this.getFlatRateUnits().forEach((flatRateUnit: IFlatRateUnit) => flatRateUnit.setIsSelected(isSelected));
    this.getDefects().forEach((defect: IDefect) => defect.setIsSelected(isSelected));

    this.getPackages().forEach((pack: IPackage): void => {
      pack.setIsSelected(isSelected);

      pack.getParts().forEach((part: IPart) => part.setIsSelected(isSelected));
      pack.getFlatRateUnits().forEach((flatRateUnit: IFlatRateUnit) => flatRateUnit.setIsSelected(isSelected));
      pack.getDefects().forEach((defect: IDefect) => defect.setIsSelected(isSelected));
    });
  }

  // TODO: Sort before setting position numbers
  private setPartPositionNumbers(): void {
    for (let i: number = 0; i < this.getParts().length; i++) {
      this.getParts()[i].setPositionNumber(i + 1);
    }
  }

  // TODO: Sort before setting position numbers
  private setFlatRateUnitPositionNumbers(): void {
    for (let i: number = 0; i < this.getFlatRateUnits().length; i++) {
      this.getFlatRateUnits()[i].setPositionNumber(i + 1);
    }
  }

  // TODO: Sort before setting position numbers
  private setPackagePositionNumbers(): void {
    for (let i: number = 0; i < this.getPackages().length; i++) {
      this.getPackages()[i].setPositionNumber(i + 1);
    }
  }

  // TODO: Sort before setting position numbers
  private setDefectPositionNumbers(): void {
    for (let i: number = 0; i < this.getDefects().length; i++) {
      this.getDefects()[i].setPositionNumber(i + 1);
    }
  }

  prepareJob(): void {
    this.setDemandCategory(this.getDemandCategory() || 'AWP');
    this.setPartPositionNumbers();
    Job.setFlatRateUnitsExternalIdIfMissing(this.getFlatRateUnits());
    this.setFlatRateUnitPositionNumbers();
    this.setDefectPositionNumbers();
    this.setPackagePositionNumbers();
    this.getPackages().forEach((pack: IPackage) => this.preparePackage(pack));
    this.setSelectedOnAllItems(true);
  }

  private preparePackage(pack: IPackage): void {
    pack.setIsSelected(true);

    if (pack.getType() === PackageType.Sip) {
      pack.setGrossPrice(0);
      pack.getParts().forEach((part: IPart) => part.setTotalCustomerGrossPrice(0));
      pack.getFlatRateUnits().forEach((flatRateUnit: IFlatRateUnit) => flatRateUnit.setGrossPrice(0));
    }

    for (let j: number = 0; j < pack.getDefects().length; ++j) {
      pack.getDefects()[j].setPositionNumber(j + 1);
    }
    this.getPackages().forEach((pack: IPackage) => Job.setFlatRateUnitsExternalIdIfMissing(pack.getFlatRateUnits()));
    for (let k: number = 0; k < pack.getFlatRateUnits().length; ++k) {
      pack.getFlatRateUnits()[k].setPositionNumber(k + 1);
    }
    for (let l: number = 0; l < pack.getParts().length; ++l) {
      pack.getParts()[l].setPositionNumber(l + 1);
    }
  }
}
