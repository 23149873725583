<div class="message-container"
     [ngClass]="{'case-active': !loadingIndicatorVisible && serviceCaseHolder.getActiveCase()}">
  @if (serviceCaseRequiredErrorVisible) {
    <ds-message tone="info" [isDismissable]="false"
                messageText="{{'common.serviceCaseRequiredError' | translate}}"></ds-message>
  } @else if (vehicleRequiredErrorVisible) {
    <ds-message tone="info" [isDismissable]="false"
                messageText="{{'common.vehicleRequiredError' | translate}}"></ds-message>
  } @else if (vin17RequiredErrorVisible) {
    <ds-message tone="info" [isDismissable]="false"
                messageText="{{'common.vinRequiredError' | translate}}"></ds-message>
  } @else if (errorIndicatorVisible) {
    <ds-message tone="critical" [isDismissable]="false"
                messageText="{{(appConfiguration.titleResource | translate) + ': ' + ('common.viewLoadingError' | translate)}}">
    </ds-message>
  } @else if (loadingIndicatorVisible) {
    <ds-progress-circle></ds-progress-circle>
    <p
      class="m-3x text-regular">{{ ('common.loading' | translate) + ' ' + (appConfiguration.titleResource | translate) + '...' }}</p>
  }
</div>
