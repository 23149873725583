import {Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {AuthInfo} from '../../../auth/auth-info';
import {AuthInfoFactory} from '../../../auth/auth-info-factory';
import {AuthenticationTokenBearerService} from '../../../auth/authentication-token-bearer.service';
import {AnalyticsAction} from '../../../core/analytics/enums/analytics-action';
import {VehicleSearchOrigin} from '../../../core/analytics/enums/vehicle-search-origin';
import {AnalyticsService} from '../../../core/analytics/services/analytics.service';
import {KeyDataLoader} from '../../../core/keydata/services/key-data.loader';
import {ServiceCaseManager} from '../../../core/service-case/models/service-case-manager';
import {UserData} from '../../../core/user/models/user-data';
import {UserService} from '../../../core/user/services/user.service';
import {Util} from '../../../util/util';
import {DmsVehicle} from '../../dms-search/dms-vehicle';
import {DmsService} from '../../dms-search/dms.service';
import {VehicleSearchOverlayState} from './vehicle-search-overlay-state';
import {VehicleSearchOverlayTab} from './vehicle-search-overlay-tab/vehicle-search-overlay-tab';

export const LOCAL_STORAGE_KEY_FOR_SEARCH_OVERLAY_STATE = 'searchOverlayState';

@Component({
  selector: 'app-vehicle-search-overlay',
  templateUrl: './vehicle-search-overlay.component.html',
  styleUrls: ['./vehicle-search-overlay.component.scss']
})
export class VehicleSearchOverlayComponent implements OnInit {
  @HostBinding('class.hidden')
  hidden = true;

  @ViewChild('searchInputElement', {static: false})
  searchInputElement: ElementRef<HTMLInputElement>;

  searchInput = '';
  searchInputValidationRegexp = '^\\s*(|[a-zA-Z0-9]{4}|[a-zA-Z0-9]{7}|[a-zA-Z0-9]{17}|[a-zA-Z0-9-]{36})\\s*$';
  searchOverlayState: VehicleSearchOverlayState;
  tabs: VehicleSearchOverlayTab[] = [];
  selectedTab: VehicleSearchOverlayTab;

  user: UserData;
  authInfo: AuthInfo;

  // used in html
  protected readonly VehicleSearchOrigin = VehicleSearchOrigin;

  constructor(private serviceCaseManager: ServiceCaseManager,
              private userService: UserService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService,
              private analyticsService: AnalyticsService,
              private dmsService: DmsService,
              private keyDataLoader: KeyDataLoader) {
    this.dmsService.dmsSettingsChanged.subscribe(() => this.createTabs());
  }

  // hide overlay if user hits escape
  @HostListener('document:keyup', ['$event'])
  keyUpHandler(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.hide();
    }
  }

  ngOnInit(): void {
    this.searchOverlayState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_FOR_SEARCH_OVERLAY_STATE));
    if (!this.searchOverlayState) {
      this.searchOverlayState = {
        recentSearchStrings: [],
        recentSearchesExpanded: true,
        selectedTab: ''
      };
    }
    this.userService.userSubject.subscribe(this.handleUserChange.bind(this));
    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(this.updateAuthInfo.bind(this));
    this.authenticationTokenBearerService.csslTokenSubject.subscribe(this.updateAuthInfo.bind(this));
    this.createTabs();
    this.restoreSelectedTab();
  }

  show(): void {
    this.hidden = false;
    this.searchInput = '';
    this.showSelectedTab();

    // focus VIN input after some small timeout, otherwise it will not work
    setTimeout(() => this.searchInputElement?.nativeElement.focus(), 100);
  }

  showKeyPool(): void {
    this.analyticsService.postKeyPoolButtonClickedEvent();
    this.selectedTab = this.tabs.find(tab => (tab.titleResource === 'case.keyPool'));
    this.show();
  }

  hide(): void {
    this.hidden = true;
  }

  selectTab(tab: VehicleSearchOverlayTab): void {
    this.selectedTab = tab;
    this.showSelectedTab();
  }

  toggleRecentSearchesPanel(): void {
    this.searchOverlayState.recentSearchesExpanded = !this.searchOverlayState.recentSearchesExpanded;
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SEARCH_OVERLAY_STATE, JSON.stringify(this.searchOverlayState));
  }

  /**
   * Checks the VIN input field, which may contain nothing, a 4-digit type code, a 7- or 17-digit VIN or a larger
   * service case id. If a service case id is entered, the service case is loaded, otherwise a new service case is
   * created, a vehicle identified by VIN or type code and added. If the VIN input field is empty, a new empty service
   * case is created. The service case is activated in each case.
   * @param vinInputModel NG model of the VIN input field.
   */
  validateSearchInputAndOpenNewTab(vinInputModel?: NgModel): void {
    if (new RegExp(this.searchInputValidationRegexp).test(this.searchInput)) {
      const input = this.searchInput.trim();
      if (input && input.length > 17) {
        // input is a case ID
        this.serviceCaseManager.openExistingServiceCaseById(input, VehicleSearchOrigin.CASE_ID)
          .finally(() => {
            // do nothing
          });
        this.postVehicleSearchAnalyticsEvents(VehicleSearchOrigin.CASE_ID);
      } else if (input && input.length > 4) {
        // input is a VIN
        const origin = input.length == 7 ? VehicleSearchOrigin.VIN7 : VehicleSearchOrigin.VIN17;
        this.serviceCaseManager.selectAndOpenServiceCaseByVin(input, origin);
        this.postVehicleSearchAnalyticsEvents(origin);
      } else if (input && input.length === 4) {
        // input is a type code
        this.serviceCaseManager.openNewServiceCaseByTypeCode(input, VehicleSearchOrigin.TYPE_CODE);
        this.postVehicleSearchAnalyticsEvents(VehicleSearchOrigin.TYPE_CODE);
      } else {
        // no input
        this.serviceCaseManager.openNewServiceCaseWithoutVehicle();
      }
      this.addSearchStringToLocalStorage(input);
      this.hide();
    } else if (vinInputModel) {
      vinInputModel.control.markAsTouched();
    }
  }

  typeCodeSelected(typeCode: any): void {
    this.hide();
    this.serviceCaseManager.openNewServiceCaseByTypeCode(typeCode, VehicleSearchOrigin.TYPE_ATTRIBUTES);
    this.postVehicleSearchAnalyticsEvents(VehicleSearchOrigin.TYPE_ATTRIBUTES);
  }

  vinSelected(vin: string): void {
    this.hide();
    this.serviceCaseManager.selectAndOpenServiceCaseByVin(vin, VehicleSearchOrigin.KEY_POOL);
    this.postVehicleSearchAnalyticsEvents(VehicleSearchOrigin.KEY_POOL);
  }

  dmsVehicleSelected(dmsVehicle: DmsVehicle, origin: string): void {
    this.hide();
    this.serviceCaseManager.selectAndOpenServiceCaseByDmsVehicle(dmsVehicle, origin);
    this.postVehicleSearchAnalyticsEvents(origin);
  }

  customerSearchEnabled(): boolean {
    return this.user?.getContext() !== 'B2E' && this.dmsService.isCustomerSearchEnabled();
  }

  orderSearchEnabled(): boolean {
    return this.user?.getContext() !== 'B2E' && this.dmsService.isOrderSearchEnabled();
  }

  licensePlateSearchEnabled(): boolean {
    return this.user?.getContext() !== 'B2E' && this.dmsService.isLicensePlateSearchEnabled();
  }

  private addSearchStringToLocalStorage(searchString: string): void {
    if (searchString) {
      // add string in upper case to the first position
      this.searchOverlayState.recentSearchStrings.unshift(searchString.toUpperCase());
      // make sure strings are unique
      this.searchOverlayState.recentSearchStrings = [...new Set(this.searchOverlayState.recentSearchStrings)];
      // limit recent searches to 10
      this.searchOverlayState.recentSearchStrings.length = Math.min(
        this.searchOverlayState.recentSearchStrings.length,
        10
      );
      localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SEARCH_OVERLAY_STATE, JSON.stringify(this.searchOverlayState));
    }
  }

  private updateAuthInfo(): void {
    const userData = this.userService.userSubject.getValue();
    if (userData) {
      this.authInfo = AuthInfoFactory.makeAuthInfo(userData);
    }
  }

  private handleUserChange(user: UserData): void {
    this.user = user;
    this.createTabs();
  }

  private createTabs(): void {
    this.tabs = [];
    if (this.user?.getContext() !== 'B2E') {
      this.tabs.push({
        titleResource: 'case.keyPool'
      });
    }
    this.tabs.push({
      titleResource: 'case.typeAttributes',
      src: '/ui/typecode/main.js'
    });
    if (this.customerSearchEnabled()) {
      this.tabs.push({
        titleResource: 'case.customerName'
      });
    }
    if (this.licensePlateSearchEnabled()) {
      this.tabs.push({
        titleResource: 'case.licensePlate'
      });
    }
    if (this.orderSearchEnabled()) {
      this.tabs.push({
        titleResource: 'case.dmsOrderNumber'
      });
    }
  }

  private showSelectedTab(): void {
    this.selectedTab.showErrorIndicator = false;
    if (this.selectedTab.src) {
      this.loadWebComponent(this.selectedTab);
    }
    this.searchOverlayState.selectedTab = this.selectedTab.titleResource;
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SEARCH_OVERLAY_STATE, JSON.stringify(this.searchOverlayState));
    if (this.selectedTab.titleResource === 'case.keyPool') {
      this.keyDataLoader.reloadKeyData();
    }
  }

  private restoreSelectedTab(): void {
    this.selectedTab = this.tabs
      .find(tab => (tab.titleResource === this.searchOverlayState.selectedTab)) || this.tabs[0];
  }

  private loadWebComponent(selectedTab: VehicleSearchOverlayTab): void {
    selectedTab.showLoadingIndicator = true;
    Util.attachScript(selectedTab.src)
      .catch(() => selectedTab.showErrorIndicator = true)
      .finally(() => selectedTab.showLoadingIndicator = false);
  }

  private postVehicleSearchAnalyticsEvents(origin: string) {
    this.analyticsService.postVehicleSearchEvent(AnalyticsAction.VEHICLE_SEARCH, origin);

    if (origin === VehicleSearchOrigin.VIN17 && this.keyDataLoader.getKeyDataForVin(this.searchInput.trim())) {
      this.analyticsService.postVehicleSearchEvent(AnalyticsAction.VEHICLE_SEARCH, 'vin-in-key-pool');
    }
  }
}
