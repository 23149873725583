import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {IBusinessPartner, IBusinessRelationship} from '@service-and-repairs/awpintegrationlib';
import packageInfo from '../../../../package.json';
import {ThinClientService} from '../../services/thin-client.service';
import {UserData} from '../user/models/user-data';

@Injectable({
  providedIn: 'root'
})
export class SupportInformationService {
  cavorsSessionId: string = sessionStorage.getItem('cavors-session-id') || 'Unknown';

  currentDateAndTime: string = this.formatCurrentDateTime();

  awpVersion: string = packageInfo.version;

  private readonly DESCRIPTION_LENGTH: number = 24;

  constructor(private readonly thinClientService: ThinClientService) {
    this.initDateTimeRefreshTimer();
  }

  collectSupportInformation(user: UserData): string {
    return '================= AWP Support Information =================='
      + this.getAwpVersion()
      + this.getThinClientVersionIfPresent()
      + this.getSessionInfo(user)
      + this.getRolesFormatted(user.getRoles())
      + this.getDealerDataIfPresent(user)
      + '\n============================================================';
  }

  private getAwpVersion(): string {
    return this.getFormattedLine('AWP version', this.awpVersion);
  }

  private getThinClientVersionIfPresent(): string {
    if (this.thinClientService.currentVersion?.trim()) {
      return this.getFormattedLine('AWP client version', this.thinClientService.currentVersion);
    } else {
      return '';
    }
  }

  private getSessionInfo(user: UserData): string {
    return this.getFormattedLine('Session ID', this.cavorsSessionId)
      + this.getFormattedLine('Time', this.currentDateAndTime)
      + this.getFormattedLine('URL', this.getUrl())
      + this.getFormattedLine('B2X context', user.getContext())
      + this.getFormattedLine('User ID', user.getId())
      + this.getFormattedLine('User name', user.getName());
  }

  private getDealerDataIfPresent(user: UserData): string {
    if (!user.isB2E()) {
      const selectedBp: IBusinessPartner = user.getBusinessPartner();
      const bps: IBusinessPartner[] = user.getBusinessPartners();
      const selectedOutlet: string = this.getFormattedLine(
        'Outlet',
        selectedBp?.getDistributionPartnerNumber() + '/' + selectedBp?.getOutletNumber() + '*' // DpNo / Outlet
        + ' - ' + selectedBp?.getBusinessPartnerId()                                                 // BP-ID
        + ' - ' + this.formatBusinessRelationships(selectedBp?.getBusinessRelationships())           // BuNos
      );
      return selectedOutlet + bps
        .filter((bp: IBusinessPartner): boolean => bp.getBusinessPartnerId() !== selectedBp.getBusinessPartnerId())
        .map((bp: IBusinessPartner): string => {
          return this.getFormattedLine(
            'Outlet',
            bp?.getDistributionPartnerNumber() + '/' + bp?.getOutletNumber() + ' ' // DpNo / Outlet
            + ' - ' + bp?.getBusinessPartnerId()                                        // BP-ID
            + ' - ' + this.formatBusinessRelationships(bp?.getBusinessRelationships())  // BuNos
          );
        })
        .join('');
    } else {
      return '';
    }
  }

  private getUrl(): string {
    return window.location.toString();
  }

  private getRolesFormatted(roles: string[]): string {
    const rolesFiltered: string[] = this.getLinesWithMaxLength(this.getRelevantRoles(roles));

    return rolesFiltered
      .map((role: string, index: number) => {
        const desc: string = index === 0 ? 'Relevant user roles' : '';
        return this.getFormattedLine(desc, role);
      })
      .join();
  }

  private getRelevantRoles(roles: string[]): string[] {
    return roles.filter((role: string) =>
      /awp|ispi|air|etk|ilean|OPUS|sgate|cddc|customerboard|esa_access|es_b2b_srv_|spt_/.test(role))
      .sort((a: string, b: string) => a.localeCompare(b, undefined, {sensitivity: 'base'}));
  }

  private getLinesWithMaxLength(array: string[]): string[] {
    const lines: string[] = [];
    const maxLineLength: number = 60;
    array.forEach((value: string): void => {
      if (lines.length > 0 && lines[lines.length - 1].length + value.length <= maxLineLength) {
        lines[lines.length - 1] += ', ' + value;
      } else {
        lines.push(value);
      }
    });

    return lines;
  }

  private formatBusinessRelationships(relationships: IBusinessRelationship[]): string {
    return relationships
      ?.map((relationship: IBusinessRelationship) => this.getFormattedBusinessNumber(relationship))
      .join(' - ');
  }

  private getFormattedBusinessNumber(relationship: IBusinessRelationship): string {
    const brands: string = relationship.getBrands().join(',');
    return `${relationship.getBusinessNumber()}/${brands}`;
  }

  private formatCurrentDateTime(): string {
    return (new DatePipe('en-US')).transform(Date.now(), 'yyyy-MM-dd HH:mm:ss zzzz');
  }

  private initDateTimeRefreshTimer(): void {
    setInterval(() => this.currentDateAndTime = this.formatCurrentDateTime(), 15000);
  }

  private getFormattedLine(desc: string, value: string): string {
    desc = desc.length > 0 ? desc + ':' : '';
    return '\n' + desc.padEnd(this.DESCRIPTION_LENGTH, ' ') + value;
  }
}
