@if (showLoadingIndicator || showVinLongRequiredError || showWebAgentCookieOpenError || showAirLoginWindowInfo) {
  <div class="message-container" [ngClass]="{'case-active': serviceCaseHolder.getActiveCase()}">
    @if (showLoadingIndicator) {
      <div class="loading-indicator">
        <ds-progress-circle class="mx-3x"></ds-progress-circle>
        <p class="text-regular">{{ ('common.loading' | translate) + ' ' + (appConfiguration.titleResource | translate) + '...' }}</p>
      </div>
    } @else if (showVinLongRequiredError) {
      <ds-message
        tone="info"
        [isDismissable]="false"
        messageText="{{'common.vinRequiredError' | translate}}"></ds-message>
    } @else if (showWebAgentCookieOpenError) {
      <ds-message
        tone="caution"
        [isDismissable]="false"
        messageText="{{'common.webAgentCookieOpenError' | translate}}"></ds-message>
    } @else if (showAirLoginWindowInfo) {
      <ds-message
        tone="info"
        [isDismissable]="false"
        messageText="{{'common.airLoginWindowInfo' | translate}}"></ds-message>
    }
  </div>
}
