import {jwtDecode, JwtPayload} from 'jwt-decode';

/**
 * This class fully resembles how we store CSSL tokens in the browser's local storage.
 * A CSSL token is a Java Web Token (JWT). To learn more about JWTs and debug/decode
 * test tokens, see https://jwt.io/.
 */
export class CsslToken {
  static readonly TOKEN_REFRESH_MARGIN_MILLIS: number = 30000;

  jwt: string; // A JWT holding basic identity information including roles. Up to around 3000 characters.
  businessContext: string; // The user's business context for which the token was issued: B2D | B2D_INTERNAL | B2E.
  creationDate: Date; // Date/time the token was stored at.
  expirationDate: Date; // Date/time the token will expire after.
  refreshDate: Date; // Date/time the token will be refreshed at.

  /**
   * Reads the token for the given business context from local storage and returns it as an instance of CsslToken.
   * @param businessContext B2D | B2D_INTERNAL | B2E
   * @return The CSSL token from local storage. If none exists, an empty token is returned instead of null or undefined.
   */
  static readFromStorage(businessContext: string): CsslToken {
    const itemKey = 'csslToken_' + businessContext;
    const stringItemFromStorage = window.sessionStorage.getItem(itemKey);
    if (!stringItemFromStorage) {
      return new CsslToken();
    }

    const parsedItemFromStorage = JSON.parse(stringItemFromStorage);
    const token = Object.assign(new CsslToken(), parsedItemFromStorage);

    // Ensure dates are properly parsed
    token.creationDate = new Date(parsedItemFromStorage.creationDate);
    token.expirationDate = new Date(parsedItemFromStorage.expirationDate);
    token.refreshDate = new Date(parsedItemFromStorage.refreshDate);

    return token;
  }

  static create(jwt: string, businessContext: string): CsslToken | undefined {
    try {
      const token = new CsslToken();
      token.jwt = jwt;
      token.businessContext = businessContext;
      token.creationDate = new Date();
      const decodedJwt: JwtPayload = jwtDecode<JwtPayload>(token.jwt);

      // CSSL tokens are valid for 290 seconds after they have been issued by CSSL. We want to refresh earlier
      // to prevent that apps use their existing token right at expiration and run into authentication problems.
      token.refreshDate = new Date(decodedJwt.exp * 1000 - CsslToken.TOKEN_REFRESH_MARGIN_MILLIS);
      token.expirationDate = new Date(decodedJwt.exp * 1000);

      return token;
    } catch (error) {
      console.error('[Webapp] [Auth] Failed to decode CSSL JWT.', error);
      return undefined;
    }
  }

  writeToStorage(): void {
    const storageKey = 'csslToken_' + this.businessContext;
    const storageValue = JSON.stringify(this);
    window.sessionStorage.setItem(storageKey, storageValue);
  }

  deleteFromStorage(): void {
    window.sessionStorage.removeItem('csslToken_' + this.businessContext);
  }

  isRefreshRequired(): boolean {
    return !this.refreshDate || (new Date()).getTime() > this.refreshDate.getTime();
  }
}
