<div class="case-tiles">
  @for (serviceCase of caseList; track serviceCase.getExternalId()) {
    <div class="case-tile">
      <div class=" meta-data container-fluid" (click)="caseSelected(serviceCase)">
        <div class="row">
          <div class="col-4">
            <div class="text-label">{{ 'case.title' | translate }}</div>
            <div class="value bold"
                 [title]="serviceCase.getTitle() ? serviceCase.getTitle() : serviceCase.getExternalId()">
              {{ serviceCase.getTitle() ? serviceCase.getTitle() : serviceCase.getExternalId() }}
            </div>
          </div>
          <div class="col-4">
            <div class="text-label">{{ 'case.creator' | translate }}</div>
            <div class="value" [title]="serviceCase.getCreatedByUserName()">
              {{ serviceCase.getCreatedByUserName() }}
            </div>
          </div>
          <div class="col-4">
            <div class="text-label">{{ 'case.owner' | translate }}</div>
            <div class="value" [title]="serviceCase.getAssignedUserName()">
              {{ serviceCase.getAssignedUserName() }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="text-label">{{ 'case.modified' | translate }}</div>
            <div class="value" [title]="serviceCase.getModifiedAt() | date:'mediumDate':'undefined':locale">
              {{ serviceCase.getModifiedAt() | date:'mediumDate':'undefined':locale }}
            </div>
          </div>
          <div class="col-4">
            <div class="text-label">{{ 'case.created' | translate }}</div>
            <div class="value" [title]="serviceCase.getCreatedAt() | date:'mediumDate':'undefined':locale">
              {{ serviceCase.getCreatedAt() | date:'mediumDate':'undefined':locale }}
            </div>
          </div>
          <div class="col-4">
            <div class="text-label">{{ 'case.comment' | translate }}</div>
            <div class="value" [title]="serviceCase.getComment()">
              {{ serviceCase.getComment() }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="text-label">{{ 'case.dmsOrderNumber' | translate }}</div>
            <div class="value" [title]="serviceCase.getDmsOrderNumber()">
              {{ serviceCase.getDmsOrderNumber() }}
            </div>
          </div>
          <div class="col-4">
            <div class="text-label">{{ 'case.dmsCustomerNumber' | translate }}</div>
            <div class="value" [title]="serviceCase.getDmsCustomerNumber()">
              {{ serviceCase.getDmsCustomerNumber() }}
            </div>
          </div>
          <div class="col-4">
            <div class="text-label">{{ 'case.appointment' | translate }}</div>
            <div class="value" [title]="serviceCase.getSchedulingInformation()?.getReceptionStart() | date:'short':'undefined':locale">
              {{ serviceCase.getSchedulingInformation()?.getReceptionStart() | date:'short':'undefined':locale }}
            </div>
          </div>
        </div>
      </div>

      @if (serviceCase.getDemandCategories()) {
        <div class="demand-categories" title="{{'case.demandCategories' | translate }}">
          @for (demandCategory of serviceCase.getDemandCategories().split(","); track demandCategory) {
            <ds-tag class="job-demand-category m-1x" size="lg">
              {{ demandCategory }}
            </ds-tag>
          }
        </div>
      }
    </div>
  }
</div>
