<div class="header px-3x py-2x">
  <span class="title-and-appointment">
    @if (!!serviceCaseHolder.getActiveCase()?.getSchedulingInformation()?.getReceptionStart()) {
      <div class="title-xs text-disabled">
        {{ 'case.appointment' | translate }}: {{ serviceCaseHolder.getActiveCase().getSchedulingInformation().getReceptionStart() | date:'short':'undefined':locale }}
      </div>
    } @else {
      <div class="title-xs text-disabled">{{ 'case.noAppointment' | translate }}</div>
    }
    <div class="text-label mt-1x"
         [title]="'case.title' | translate">{{ serviceCaseHolder.getActiveCase()?.getTitle() }}
    </div>
  </span>
  <app-shopping-cart-button class="ms-3x" [active]="shortlistViewState.visible" (click)="close()"></app-shopping-cart-button>
  <button
    ds-toggle-button
    variant="ghost-muted"
    icon="pin"
    (click)="togglePinnedState()"
    [checked]="isPinned">
  </button>
</div>

@if (showErrorIndicator) {
  <div class="message-container p-3x">
    <ds-message tone="critical"
                [isDismissable]="false"
                messageText="{{('common.viewLoadingError' | translate)}}">
    </ds-message>
  </div>
} @else {
  @for (serviceCase of serviceCaseHolder.serviceCases; track serviceCase.getExternalId()) {
    <div class="shortlist-component-container" [hidden]="isHidden(serviceCase)">
      <ueg-awp-service-case-shortlist
        #shortlistWebComponent
        [activeCase]="serviceCase"
        [userData]="user"
        [authInfo]="authInfo"
        [locale]="locale"
        [showFlatRateUnitAmount]="showFlatRateUnitAmount()"
        [leadsDealerConfig]="leadsConfiguration"
        (openDetailsClicked)="openServiceCaseDetails()"
      ></ueg-awp-service-case-shortlist>
    </div>
  }
}
