import {Brand} from '@service-and-repairs/awpintegrationlib';
import {UserData} from '../core/user/models/user-data';
import {Util} from '../util/util';
import {AuthInfo} from './auth-info';
import {CsslBrands} from './cssl/cssl-brands';
import {CsslToken} from './cssl/cssl-token';
import {OidcToken} from './oidc/oidc-token';

export class AuthInfoFactory {
  static makeAuthInfo(user: UserData): AuthInfo {
    const oidcToken = OidcToken.readFromStorage(user.getContext());
    const csslToken = CsslToken.readFromStorage(user.getContext());
    if (!oidcToken.access_token) {
      console.error(Util.logPrefix('Webapp', 'Auth') +
        `Creating incomplete 'auth-info' object: OIDC access token is missing in local storage.`);
    }
    if (!csslToken.jwt) {
      console.error(Util.logPrefix('Webapp', 'Auth') +
        `Creating incomplete 'auth-info' object: CSSL token is missing in local storage.`);
    }

    const authInfo = new AuthInfo();
    authInfo.wenOidcAccessToken = oidcToken.access_token;
    authInfo.csslToken = csslToken.jwt;
    authInfo.authScheme = user.getAuthScheme();
    authInfo.authRealm = user.getAuthRealm();
    authInfo.countryCode = user.getB2XCountryCode();
    authInfo.roles = user.getRoles();
    if (user.isB2E()) {
      authInfo.brands = AuthInfoFactory.awpBrandsToCsslBrands(user.getSelectedBrands());
    } else {
      authInfo.distributionPartnerNumber = user.getBusinessPartner().getDistributionPartnerNumber();
      authInfo.outletNumber = user.getBusinessPartner().getOutletNumber();
      authInfo.businessNumber = user.getBusinessNumber();
      authInfo.brands = AuthInfoFactory.awpBrandsToCsslBrands(user.getBrandsOfSelectedBusinessPartner());
    }

    return authInfo;
  }

  static awpBrandsToCsslBrands(brands: Brand[]): CsslBrands[] {
    if (brands?.length > 0) {
      return brands.map(AuthInfoFactory.awpBrandToCsslBrand);
    } else {
      console.error(Util.logPrefix('Webapp', 'Auth') +
        `Creating incomplete 'auth-info' object: List of AWP brands is undefined or empty.`);
      return [];
    }
  }

  private static awpBrandToCsslBrand(brand: Brand): CsslBrands {
    switch (brand) {
      case Brand.BMW:
        return CsslBrands.BMW;
      case Brand.BMW_i:
        return CsslBrands.BMW_I;
      case Brand.MINI:
        return CsslBrands.MINI;
      case Brand.MOT:
        return CsslBrands.BMW_MOTORCYCLE;
      case Brand.RR:
        return CsslBrands.ROLLS_ROYCE;
      case Brand.C_1:
        return CsslBrands.C_1;
      case Brand.ZINORO:
        return CsslBrands.BMW_ZINORO;
      case Brand.HUSQVARNA:
        return CsslBrands.HUSQVARNA;
      case Brand.TOYOTA:
        console.warn(Util.logPrefix('Webapp', 'Auth') + 'AWP brand TOYOTA cannot be mapped to CSSL brand');
        return CsslBrands.UNKNOWN;
      case Brand.UNKNOWN:
        console.warn(Util.logPrefix('Webapp', 'Auth') + `Unknown AWP brand ${brand} cannot be mapped to CSSL brand`);
        return CsslBrands.UNKNOWN;
    }
  }
}
