<div class="overlay shadow-modal p-4x">
  <div class="overlay-title-bar">
    <div class="overlay-title title-md">{{ 'case.vehicleSearch' | translate }}</div>
    <button ds-button variant="ghost-muted" icon="close" class="overlay-close-button" (click)="hide()"></button>
  </div>
  <div class="vehicle-search-explanation">
    {{ 'case.vehicleSearchExplanation' | translate }}
  </div>
  <div class="overlay-input-bar">
    <ds-form-field>
      <input
        ds-input
        ds-form-validation
        autofocus
        type="text"
        autocomplete="off"
        name="vin-input"
        maxlength="36"
        list="recent-searches-list"
        #searchInputElement
        #vinInputModel="ngModel"
        [(ngModel)]="searchInput"
        (keyup.enter)="validateSearchInputAndOpenNewTab(vinInputModel)"
        (keydown)="vinInputModel.control.markAsUntouched()"
        [pattern]="searchInputValidationRegexp"
        [placeholder]="'case.searchInputPlaceHolderOverlay' | translate"
      />
      <datalist id="recent-searches-list">
        <option *ngFor="let recentSearchString of searchOverlayState.recentSearchStrings"
                [value]="recentSearchString">
      </datalist>
      <ds-error>{{ 'case.vinValidationError' | translate }}</ds-error>
    </ds-form-field>
    <button ds-button variant="primary" icon="search" class="case-search-button"
            (click)="validateSearchInputAndOpenNewTab(vinInputModel)"></button>
  </div>

  <div class="title-md">{{ 'case.vehicleSearchAdvanced' | translate }}</div>
  <ds-tabs>
    <ds-tabs-label *ngFor="let tab of tabs"
                   [for]="tab.titleResource"
                   [isSelected]="selectedTab.titleResource === tab.titleResource"
                   (click)="selectTab(tab)"
    >{{ tab.titleResource | translate }}
    </ds-tabs-label>
    <ds-tabs-content id="case.typeAttributes">
      <app-vehicle-search-overlay-tab [selectedTab]="selectedTab">
        <ueg-awp-typecode [authInfo]="authInfo"
                          [locale]="user?.getLocale()"
                          (typeCodeSelected)="typeCodeSelected($event['detail'])">
        </ueg-awp-typecode>
      </app-vehicle-search-overlay-tab>
    </ds-tabs-content>

    <ds-tabs-content id="case.keyPool">
      <app-vehicle-search-overlay-tab [selectedTab]="selectedTab">
        <app-key-pool (vinSelected)="vinSelected($event)"></app-key-pool>
      </app-vehicle-search-overlay-tab>
    </ds-tabs-content>

    <ds-tabs-content id="case.customerName" *ngIf="customerSearchEnabled()">
      <app-vehicle-search-overlay-tab [selectedTab]="selectedTab">
        <app-customer-search
          (selectedVehicle)="dmsVehicleSelected($event, VehicleSearchOrigin.DMS_CUSTOMER_NAME)"></app-customer-search>
      </app-vehicle-search-overlay-tab>
    </ds-tabs-content>

    <ds-tabs-content id="case.licensePlate" *ngIf="licensePlateSearchEnabled()">
      <app-vehicle-search-overlay-tab [selectedTab]="selectedTab">
        <app-dms-license-plate-search
          (selectedVehicle)="dmsVehicleSelected($event, VehicleSearchOrigin.DMS_LICENSE_PLATE)"></app-dms-license-plate-search>
      </app-vehicle-search-overlay-tab>
    </ds-tabs-content>

    <ds-tabs-content id="case.dmsOrderNumber" *ngIf="orderSearchEnabled()">
      <app-vehicle-search-overlay-tab [selectedTab]="selectedTab">
        <app-dms-order-search
          (selectedVehicle)="dmsVehicleSelected($event, VehicleSearchOrigin.DMS_ORDER_NUMBER)"></app-dms-order-search>
      </app-vehicle-search-overlay-tab>
    </ds-tabs-content>
  </ds-tabs>
</div>

<div class="background" (click)="hide()"></div>
